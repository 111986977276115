<template>
  <b-card :title="serviceName && clientName ? `Images de la commande ${serviceName} pour le client ${clientName} ` : 'Photos'">
    <b-container
      fluid
      class="p-1 bg-dark"
    >
      <span
        v-if="pictures == null"
        class="text-center font-small-4"
      >
        {{ isNull }}
      </span>

      <span
        v-else-if="pictures.length == 0"
        class="text-center font-small-4"
      >
        Chargement ...
      </span>
      <span
        v-else-if="pictures == -1"
        class="text-center font-small-4"
      >
        {{ defaultValue }}
      </span>
      <b-row v-else>
        <b-col
          v-for="picture in pictures"
          :key="picture"
        >
          <b-img
            style="cursor: pointer"
            thumbnail
            fluid
            :src="picture"
            @click="openPicture(picture)"
          />
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import {
  BImg,
  BContainer,
  BRow,
  BCol,
  // BCardText,
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BImg,
    BContainer,
    // BCardText,
    BRow,
    BCol,
  },
  props: {
    serviceName: {
      type: String,
      default: '',
    },

    isNull: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: String,
      default: '',
    },
    clientName: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-prop-types
    pictures: {
      default: [],
    },
  },
  methods: {
    openPicture(url) {
      window.open(url)
    },
  },
}
</script>
