<template>
  <div>
    <b-breadcrumb class="mb-2">
      <b-breadcrumb-item to="/professionals">Liste des pros</b-breadcrumb-item>
      <b-breadcrumb-item active>Détails</b-breadcrumb-item>
    </b-breadcrumb>
    <div
      v-if="isLoadingProDetails"
      class="d-flex justify-content-center mb-1"
      style="padding-top: 30vh"
    >
      <b-spinner
        variant="info"
        style="width: 3rem; height: 3rem;"
        class="mr-1"
      />
    </div>

    <div
      v-else-if="error"
      class="text-center mt-5"
      style="padding-top: 30vh"
    >
      <h1 class="text-danger">
        <feather-icon
          icon="XCircleIcon"
          size="100"
        />
      </h1>
      <p
        class="font-medium-1 text-danger  font-weight-normal"
      >
        {{ isError }}
      </p>
    </div>
    <template v-else>
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <b-card>
            <b-row>
              <!-- User Info: Left col -->
              <b-col
                cols="21"
                xl="6"
                class="d-flex justify-content-between flex-column"
              >
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                  <b-avatar
                    :text="avatarText(pro.full_name)"
                    :src="pro.profile_image"
                    size="104px"
                    rounded
                  />
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                      <h4 class="mb-0">
                        {{ pro.full_name }}
                      </h4>
                      <span class="card-text">{{ pro.email }}</span>
                    </div>
                    <div class="d-flex flex-wrap">
                      <div class="d-flex align-items-center mr-2">
                        <b-avatar
                          variant="light-secondary"
                          rounded
                        >
                          <feather-icon
                            icon="PhoneIcon"
                            size="18"
                          />
                        </b-avatar>
                        <div class="ml-1">
                          <h5 class="mb-0">
                            Téléphone
                          </h5>
                          <small class="font-small-3">{{
                            pro.phone_number
                          }}</small>
                        </div>
                      </div>

                      <div class="d-flex align-items-center">
                        <b-avatar
                          variant="light-secondary"
                          rounded
                        >
                          <feather-icon
                            icon="MapPinIcon"
                            size="18"
                          />
                        </b-avatar>
                        <div class="ml-1">
                          <h5 class="mb-0">
                            Adresse
                          </h5>
                          <small class="font-small-3">{{ pro.address }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- User Stats -->
                <div class="d-flex flex-wrap mt-2">
                  <b-button
                    variant="primary"
                    @click="goToUpdateProPage()"
                  >
                    Modifier
                  </b-button>
                  <div v-if="pro.status != 0">
                    <b-button
                      v-if="pro.status == 2"
                      variant="outline-warning"
                      class="ml-1"
                      @click="applyUnsuspendedProAction"
                    >
                      Annuler la suspension
                    </b-button>
                    <b-button
                      v-else
                      variant="outline-danger"
                      class="ml-1"
                      @click="applySuspendedProAction"
                    >
                      Suspendre
                    </b-button>
                  </div>
                </div>
              </b-col>

              <!-- Right Col: Table -->
              <b-col
                cols="12"
                xl="6"
              >
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Date d'inscription</span>
                    </th>
                    <td class="pb-50">
                      {{ moment(pro.created_at).locale("fr").format("llll") }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="UserCheckIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Validé le </span>
                    </th>
                    <td class="pb-50">
                      {{
                        pro.accepted_at != null
                          ? moment(pro.accepted_at).locale("fr").format("llll")
                          : "----"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="ApertureIcon"
                        class="mr-75"
                      />
                      <span
                        class="font-weight-bold"
                      >Nombre de job réaliseé</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ totalWorks }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="StarIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Moyenne des étoiles</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ proMoyenne }}
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <b-card
            no-body
            class="border-primary"
          >
            <b-card-header
              class="
                d-flex
                justify-content-between
                align-items-center
                pt-75
                pb-25
              "
            >
              <h5 class="mb-0">
                Status du pro
              </h5>
              <div v-if="pro.status == 2">
                <b-badge
                  pill
                  variant="light-danger"
                  class="text-capitalize font-small-3"
                >
                  suspendu
                </b-badge>
              </div>
              <div v-else>
                <b-badge
                  v-if="pro.status == 1"
                  pill
                  variant="light-success"
                  class="text-capitalize font-small-3"
                >
                  Validé
                </b-badge>
                <b-badge
                  v-else
                  pill
                  variant="light-warning"
                  class="text-capitalize font-small-3"
                >
                  En attente
                </b-badge>
              </div>
            </b-card-header>
            <br>
            <b-card-body>

              <div v-if="getUser.role.slug === 'super-administrateur'">
                <b-button
                :disabled="pro.status != 0 && pro.status != 3 "
                  variant="success"
                  block
                  @click="applyValidateCandidateProAction"
                >
                  Valider la candidature du pro
                </b-button>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <!-- offers -->
      <b-card title="Services & Réalisation">
        <b-table
          ref="refInvoiceListTable"
          :items="pro.services"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="Liste vide"
          class="position-relative"
        >

          <!-- Column: Invoice Status -->
          <template #cell(pivot)="data">
            <p>{{ data.item.pivot.description }}</p>
          </template>

          <!-- Column: professional -->
          <template #cell(created_at)="data">
            <feather-icon
              icon="EyeIcon"
              style="cursor: pointer"
              size="30"
              class="align-middle text-body"
              @click="
                showWorksPicture(
                  data.item.name,
                  JSON.parse(data.item.pivot.works_picture)
                )
              "
            />
          </template>
        </b-table>
      </b-card>
       <works-picture
        :serviceName="serviceName"
        :default-value="defaultValue"
        :is-null="isNull"
        :pictures="pictures"
      />
    </template>
  </div>
</template>

<script>
import {
  BAlert,
  BCard,
  BRow,
  BCol,
  // BFormInput,
  BButton,
  BTable,
  // BForm,
  // BFormTextarea,
  // BFormGroup,
  // BMedia,
  BAvatar,
  BLink,
  BBadge,
  // BDropdown,
  // BDropdownItem,
  BCardHeader,
  // BFormSelect,
  BSpinner,
  BCardBody,
  BBreadcrumb,
  BBreadcrumbItem
  // BPagination,
  // BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
// import vSelect from 'vue-select'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import WorksPicture from './WorksPicture.vue'

export default {
  components: {
    BCard,
    // BFormTextarea,
    // BFormSelect,
    BSpinner,
    // BForm,
    // BFormGroup,
    WorksPicture,
    // ValidationProvider,
    // ValidationObserver,
    BCardHeader,
    BCardBody,
    BAlert,
    BRow,
    BCol,
    // BFormInput,
    BButton,
    BTable,
    // BMedia,
    BAvatar,
    BLink,
    BBadge,
    BBreadcrumb,
    BBreadcrumbItem
    // BDropdown,
    // BDropdownItem,
    // BPagination,
    // BTooltip,
    // vSelect,
  },
  data() {
    return {
      pro: null,
      isLoadingProDetails: false,
      isNull: "Ce pro n'a fourni aucune réalisation pour son service",
      defaultValue: "Cliquez sur l'icon pour afficher les réalisation ici",
      required,
      error: false,
      isError: '',
      alphaNum,
      serviceName: '',
      pictures: -1,
      totalWorks: 'chargement...',
      proMoyenne: 'chargement...',
      tableColumns: [
        { key: 'name', label: 'Nom du service', sortable: true },
        { key: 'pivot', label: 'Description', sortable: true },
        { key: 'created_at', label: 'Réalisation', sortable: true },
        // { key: "actions" },
      ],
      avatarText,
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('professionals', ['getProDetails']),
  },

  watch: {
  },
  created() {
    if (this.getProDetails == null) {
      this.applyGetProDetailsAction()
    } else {
      this.pro = this.getProDetails
      this.applyGetProMetaDataAction(this.pro.id)
      this.appyGetProComments(this.pro.id)
    }
  },
  methods: {
    goToUpdateProPage() {
      this.$router.push(`/professionals/${this.$route.params.id}/update`)
    },
    ...mapActions(['getS3FileTemporaryUrlAction']),

    ...mapActions('professionals', [
      'validateProAction',
      'suspendProAction',
      'unSuspendProAction',
      'getProfessionalsAction',
      'getProfessionalsMetaDataAction',
      'getProfessionalsCommentsAction',
      'getProfessionalsMetaDataAction',
      'getProfessionalsDetailsAction',
      'validateCandidatureProAction'
    ]),

    async showWorksPicture(serviceName, pictures) {
      this.serviceName = serviceName
      await this.applyGetS3FileTemporaryUrlAction(pictures)
    },
    applyGetS3FileTemporaryUrlAction(files) {
      if (files != null) {
        this.pictures = []
        files.forEach(element => {
          this.getS3FileTemporaryUrlAction(element)
            .then(response => {
              this.pictures.push(response.fileUrl)
            })
            .catch(error => {
              console.log(error)
              this.pictures = -1
            })
        })
      } else {
        this.pictures = files
      }
    },

    applyValidateCandidateProAction() {
      this.$swal({
        title: 'Validation de la candidature du pro',
        text: 'Êtes-vous sûr de vouloir validé la candidature de ce pro',
        icon: 'info',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.validateCandidatureProAction(this.pro.id),
      })
        .then(response => {
          console.log(response)
          if (response.value) {
            this.applyGetProDetailsAction()
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Candidature du Pro validé avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
          this.getProfessionalsAction()
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyValidateProAction() {
      this.$swal({
        title: 'Validation du pro',
        text: 'Êtes-vous sûr de vouloir validé  ce pro',
        icon: 'info',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.validateProAction(this.pro.id),
      })
        .then(response => {
          console.log(response)
          if (response.value) {
            this.applyGetProDetailsAction()
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Pro validé avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
          this.getProfessionalsAction()
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    applyGetProDetailsAction() {
      this.isLoadingProDetails = true
      this.getProfessionalsDetailsAction(this.$route.params.id).then(response => {
        this.isLoadingProDetails = false
        this.pro = response.data
        this.applyGetProMetaDataAction(this.pro.id)
        this.appyGetProComments(this.pro.id)
      }).catch(error => {
        this.isLoadingProDetails = false
        this.error = true
        this.isError = Object.values(error.response.data)[0]
      })
    },
    applyGetProMetaDataAction(id) {
      this.getProfessionalsMetaDataAction(id).then(response => {
        this.totalWorks = response.works.total
      })
    },

    appyGetProComments(id) {
      this.getProfessionalsCommentsAction(id).then(response => {
        if (response.data.length > 0) {
          let somme = 0
          response.data.forEach(element => {
            somme += element.note
          })

          this.proMoyenne = somme / response.data.length
        } else {
          this.proMoyenne = 0
        }
      })
    },
    applySuspendedProAction() {
      this.$swal({
        title: 'Suspension du pro',
        text: 'Êtes-vous sûr de vouloir  suspendre ce pro',
        icon: 'info',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.suspendProAction(this.pro.id),
      })
        .then(response => {
          console.log(response)
          if (response.value) {
            this.pro = response.value.data
            this.applyGetProDetailsAction()
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Pro suspendu avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
          this.getProfessionalsAction()
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    applyUnsuspendedProAction() {
      this.$swal({
        title: `Annuler la suspension de ${this.pro.full_name}`,
        text: 'Êtes-vous sûr de vouloir annuler la suspensionde  ce pro',
        icon: 'info',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.unSuspendProAction(this.pro.id),
      })
        .then(response => {
          console.log(response)
          if (response.value) {
            this.pro = response.value.data
            this.applyGetProDetailsAction()
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Suspension annulée avec succè',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
          this.getProfessionalsAction()
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
